.about {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
  padding-top: 20px;
  background-color: #ccb42c;

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-right: 30px;
    max-width: 500px;
  }

  h3 {
    text-align: right;
    font-size: 50px;
    @include heavy-font;
    color: $darkgreen;
    margin: 0;
  }

  p {
    text-align: right;
    font-size: 20px;
    @include light-font;
    color: $grey;
  }

  .img-container {
    display: flex;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
  }

  img {
    width: 350px;
    align-self: center;
  }

  .links {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 30px;

    .link-box {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
      background-color: $darkgreen;
      width: 130px;
      height: 30px;
    }

    a {
      text-align: center;
      @include light-font;
      color: $lightgreen;
      text-decoration: none;
      font-size: 20px;
      padding: 5px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {

    flex-direction: column-reverse;
    justify-content: center;

    .text-container {
      flex-direction: column;
      justify-content: space-between;
      max-width: 100vw;
      padding: 10px;
    }

    h3 {
      text-align: center;
      align-self: center;
    }

    p {
      max-width: 80vw;
      text-align:justify;
      align-self: center;
      font-size: 30px;
      margin: 0;
    }

    img {
      padding: 20px;
    }

    .links {
      flex-direction: row;
      padding: 0;
      padding-top: 20px;

      .link-box {
        width: 150px;
        height: auto;
      }

      a {
        font-size: 30px;
      }
    }

  }

  @media only screen and (max-width: 767px) {

    flex-direction: column-reverse;
    justify-content: center;

    .text-container {
      flex-direction: column;
      justify-content: space-between;
      max-width: 100vw;
      padding: 10px;
    }

    h3 {
      text-align: center;
      align-self: center;
      font-size: 40px;
    }

    p {
      max-width: 80vw;
      text-align:justify;
      align-self: center;
      font-size: 20px;
      margin: 0;
    }

    img {
      padding: 20px;
      max-width: 80vw;
    }

    .links {
      flex-direction: row;
      padding: 0;
      padding-top: 20px;

      .link-box {
        width: auto;
        height: auto;
      }

      a {
        font-size: 20px;
      }
    }

  }

}