.projects {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;

  h2 {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 30px;
    @include light-font;
    color: $grey;
  }

  .first-section {
    width: 250px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px;
  }

  .section {
    width: 250px;
    height: 350px;
    background-color: $darkgreen;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px;
  }

  h3 {
    text-align: center;
    color: $lightgreen;
    @include heavy-font;
    font-size: 25px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 10px;
  }

  p {
    padding-left: 10px;
    padding-right: 10px;
    @include light-font;
    color: $lightgreen;
    font-size: 20px;
    margin: 0px;
  }

  a {
    text-align: center;
    text-decoration: none;
    color: $lightgreen;
    padding-bottom: 10px;
    margin-top: 10px;
    @include light-font;
    font-size: 20px;
  } 

  @media only screen and (max-width: 767px) {

    .first-section {
      height: 150px;
    }

  }

}
