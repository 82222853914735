@import './variables.scss';
@import './header.scss';
@import './home.scss';
@import './projects.scss';
@import './pingpong.scss';
@import './about.scss';
@import './astrology.scss';
@import './lostandfound.scss';


head {
  background-color: $lightgreen;
  margin: 0;
  width: 100vw;
}

body {
  background-color: $lightgreen;
  margin: 0;
  width: 100vw;
  height: 100vh;
}

html {
  background-color: $lightgreen;
}