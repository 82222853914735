header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
  background-color: $darkgreen;
  color: $grey;

  h1 {
    font-size: 40px;
    @include heavy-font;
    font-style: italic;
    text-align: center;
    align-self: center;
    color: $lightgreen;
    text-decoration: underline $lightgreen 10px;
    padding-bottom: 10px;
  }

  .nav-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  a {
    text-align: center;
    align-self: center;
    color: $lightgreen;
    text-decoration: none;
    font-size: 30px;
    @include heavy-font;
    padding: 40px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
      justify-content: space-around;
      padding: 0;

      h1 {
        font-size: 40px;
        margin: 0;
      }

      a {
        font-size: 30px;
        padding: 20px;
      }

      .nav-container {
        justify-content: space-between;
      }

    }

    @media only screen and (max-width: 767px) {

      flex-direction: column;
      justify-content: center;
      padding: 0;

      h1 {
        font-size: 40px;
        margin: 0;
        padding-top: 20px;
        padding-bottom: 10px; 
      }

      a {
        font-size: 20px;
        padding: 15px;
      }

      .nav-container {
        justify-content: space-evenly;
      }

    }

}  
