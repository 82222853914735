.home {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #ccb42c;

  .text {
    text-align: left;
    align-self: center;
    padding: 20px;
  }

  h2 {
    @include heavy-font;
    color: $darkgreen;
    font-size: 50px;
  }

  p {
    @include light-font;
    color: $grey;
    font-size: 20px;
  }

  a {
    @include light-font;
    text-decoration: none;
    color: $grey;
    font-size: 20px;
  }

  img {
    padding: 10px;
    align-self: center;
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;

    img {
      width: 70vw;
    }

    h2 {
      font-size: 40px;
    }

    p {
      font-size: 30px;
    }

    a {
      font-size: 30px;
      
    }
  }

  @media only screen and (max-width: 767px) {

    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      width: 90vw;
    }

  }
  
}




